body,
html {
  font-family: 'Lato', arial, helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size:16px;
  line-height:1.8em;
}

a {
  outline:0 !important;
  color:#c0001f
}
a:focus,
a:hover {
  color:#5a1516
}

/* COLORS ------------------  */
h1,
span.red {
  color:#c0001f
}
h1 {
  font-weight:bold;
  margin-bottom:29px;
}
h2 {
  font-weight:normal;
  margin:0;
  padding:0;
}
h3 {
  font-weight:normal;
  margin:0;
  padding:5px 0 0;
  font-size:15px;
  color:#c0001f;
}
#intro h2 {
  font-weight:bold;
  font-size:20px;
  color:#c0001f;
  line-height:1.5em;
}
#intro .col-md-4 {
  padding-bottom:20px;
}
p {
  margin:0 0 29px;
}
span.white {
  color:#ffffff;
}

.navbar-nav > li > a {
  padding-top: 14px;
  padding-bottom: 16px;
}

.btn-default {
  background:url(/images/btn-default.png) 0 0 no-repeat;
  border:0px;
  padding:12px 45px;
  text-align: center;
  color: #ffffff;
  border-radius:0;
  display:inline-block;
}
.btn-default:focus,
.btn-default:active,
.btn-default:hover {
  background:url(/images/btn-default.png) 0 -48px no-repeat !important;
  color: #ffffff !important;
}
.btn-default i {
  padding-right:10px;
}
.btn-primary {
  background:#c0001f;
  border-bottom:2px solid #5a1516;
  border-right:0px;
  border-left:0px;
  border-top:0px;
  color: #ffffff;
  border-radius:0;
  display:inline-block;
}

.btn-primary:hover {
  background:#c0001f;
  border-bottom:2px solid #000000;
}

/* GENERAL STYLES ------------------  */
section.space {
  padding:30px 0px;
}
section.space-lg {
  padding:50px 0px;
}
#nav.affix {
  position: fixed;
  top: 0;
  width: 100%;
  z-index:10;
}

/* HEADER ------------------  */
header {
  background-color: #c0001f;
}
header h2 {
  color:#690d0e;
  font-size:24px;
  font-family: 'Radley', serif;
  float:left;
  width:100%;
}
header .bg-header {
  background: url(/images/headers/header-01.jpg) no-repeat top right;
  background-size: cover;
}
header .container-fluid .col-sm-4 {
  z-index:100;
}
header .logo-wrapper {
  display:block;
  height:398px;
  width:400px;
  left:0;
  top:0px;
  background:#c0001f;
  position:absolute;
  z-index:1000;
}
.s-overlay {
  position:absolute;
  width:237px;
  height:398px;
  background:url(/images/header-overlay.png) no-repeat;
  left:400px;
  top:0;
  z-index:900;
}

.navbar-default {
  border-top:1px solid #ffffff;
  margin-bottom: 0px;
  background-color: #000000;
}
.navbar-default .navbar-nav > li > a {
  color:#ffffff;
  text-transform: lowercase;
}
.navbar-default .navbar-nav > .active>a,
.navbar-default .navbar-nav > .active>a:focus,
.navbar-default .navbar-nav > .active>a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color:#c0001f;
  background: transparent;
}

#partners {
  background:#eeeeee;
  -webkit-box-shadow:0 0 10px 1px rgba(0, 0, 0, 0.3);
  box-shadow:0 0 10px 1px rgba(0, 0, 0, 0.3);
  margin-top:50px;
  overflow:hidden;
}
#partners .image {
  background:#ffffff;
  display:block;
  width:100%;
  text-align:center;
  border:1px solid #bbbbbb;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}
#partners .image:hover {
  /*opacity: 0.7;*/
  border:1px solid #c0001f;
}
#partners .image img {
  max-width: 100%;
}
.sponsor-item {
  margin-bottom:30px;
  float:left;
  width:100%;
  position:relative;
}
.sponsor-item .image {
  margin:15px 0;
}
.sponsor-title,
.activiteit-title {
  background:#eee;
  float:left;
  width:100%;
  padding:15px 15px;
}
.sponsor-title h2,
.activiteit-title h2 {
  margin:0;
  padding:10px 0;
  font-size:18px;
}
.sponsor-title .stars {
  padding-top:5px;
  color:#c0001f
}
.sponsor-content {
  position: relative;
  background:#fff;
  border:1px solid #eee;
  padding:15px 15px;
  width:100%;
  float:left;
}
.sponsor-item-three-star {
  padding:15px 0 0 0;
}
.sponsor-content.three-star {
  padding:15px 15px 75px 15px;
}
.sponsor-content .image {
  border-bottom:1px solid #eee;
  padding-bottom:15px;
}
.sponsor-content p {
  margin-bottom:0;
}
.sponsor-content p:last-child {
  margin-bottom:50px;
  padding-bottom:50px;
}
.sponsor-item {
  position:relative;
}
.sponsor-item .btns {
  list-style:none;
  padding:0;
  margin:15px 0 0 0 ;
  position:absolute;
  bottom:15px;
  left:15px;
}
.sponsor-item .btns li {
  float:left;
  margin-right:5px;
}
#one-star-sponsors {
  background:#f9f9f9;
  padding:30px 0 0 0;
}
h2.title {
  margin:0 0 30px 0;
  color:#c0001f
}
#one-star-sponsors .sponsor-title {
  background:#303030;
  color:#ffffff;
}

.activiteit {
  margin-bottom:30px;
  width:100%;
  float:left;
  position:relative;
}
.activiteit-title {
  min-height:110px;
}
.activiteit-title h2 {
  font-size:21px;
  min-height:66px;
  font-weight:bold;
  width:100%;
}
.modal-body h4,
h4.modal-title,
.activiteit-title .date {
  color:#c0001f
}
.activiteit-title .pull-left {
  width:100%;
  display:block;
}
.activiteit-title .date {
  min-height:30px;
  display:block;
}
.activiteit-content {
  position:relative;
  float:left;
  width:100%;
}
.activiteit-content .btn {
  position:absolute;
  bottom:0;
  right:0;
}
.modal-body .container-fluid {
  padding:0;
}
.modal-body .fa-ul li {
  border-bottom:1px solid #eee;
}
#intro h1 {
  text-align:center;
  line-height:1.3em;
  margin-top:0px;
}
#testimonials {
  background-color:#c0001f;
  position:relative;
  height:397px;
}
.bgcolor {
  background:#5a1516 url(/images/bg-schuin.jpg) no-repeat top right;
  width:50%;
  position:absolute;
  z-index:0;
  height:100%;

}
#testimonials .testimonial-logo,
#testimonials .testimonial-text {
  height:397px;
}
#testimonials .image,
#testimonials .testimonial-text{
  display: table;
  height:397px;
}
#testimonials .image div,
#testimonials .testimonial-text div{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  position: relative;
}
#testimonials .testimonial-text {
  color:#000;
  padding:0px 0px 0px 80px;
}
#testimonials .testimonial-text h2 {
  line-height:1.5em;
  font-weight:bold;
  quotes: "“" "”" "‘" "’";
}
#testimonials .testimonial-text h2:before {
  content: open-quote;
  color:#5a1516;
  position: absolute;
}
#testimonials .testimonial-text h2:after {
  content: close-quote;
  color:#5a1516;
  position:relative;
  right:0;
}
#testimonials .testimonial-text h2,
#testimonials .testimonial-text h3 {
  margin:0px;
  padding:0px;
  text-align:left;
  padding-left:80px;
  position:relative;
}
#testimonials .testimonial-text h3 {
  font-weight:normal;
  padding-top:15px;
}
/* Swiper */
.swiper-container {
  width: 100%;
  height:100%;
  margin: 0px auto;
}
.swiper-container.swiper1 {
  height: 397px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

#sponsoredby {
  background:#303030;
  text-align: center;
  color:#ffffff;
  font-size:12px;
}
#sponsoredby ul {
  margin:0px auto;
  padding:0px;
  list-style:none;
  display:inline-block;
  text-align: center;
}
#sponsoredby ul li {
  display:inline;
}
#sponsoredby ul li a {
  float:left;
  max-width:100px;
  margin:0px 30px 0px 0px;
}

/* VACATURES ---------------*/
#vacatures {
  h1 {
    margin-bottom:15px;
  }
  h2 {
    color:#c0001f;
  }
  .activiteit-title {
    padding:25px 25px 45px 25px;
    h2 {
      color:#c0001f;
      min-height:1px;
      font-size:22px;
      padding-top:0;
    }
    .vacature-details {
      margin:0 0 10px 0;
      padding:0;
    }
  }
  #wie {
    ul {
      list-style:none;
      margin:5px 0 30px 0;
      position:relative;
      float:left;
      width:100%;
      display:block;
      padding:0;
      li {
        width:100%;
        float:left;
        display:block;
        padding-left:25px;
        position:relative;
        &:before {
          content:"\f00c";
          font-family: FontAwesome;
          position:absolute;
          left:0;
          top:0;
          font-size:18px;
          color:#1C9B72;
        }
      }
    }
  }
  .vacature-details {
    margin: 0 0 20px;
    padding: 0 0 20px;
    list-style: none;
    width: 100%;
    float: left;
    position: relative;
    border-bottom:1px solid #eee;
    li {
      position:relative;
      float:left;
      text-transform:uppercase;
      font-weight:700;
      &.company {
        margin-right: 25px;
        padding-left: 26px;
        &:before {
          content: "\f0b1";
          font-family: FontAwesome;
          position: absolute;
          left: 0;
          top: 0px;
          font-size: 18px;
        }
      }
      &.city {
        margin-right: 25px;
        padding-left: 18px;
        &:before {
          content: "\f041";
          font-family: FontAwesome;
          position: absolute;
          font-size: 20px;
          left: 0;
          top: -2px;
        }
      }
      &.hours {
        padding-left: 24px;
        &:before {
          content: "\f017";
          font-family: FontAwesome;
          position: absolute;
          left: 0;
          top: -2px;
          font-size: 20px;
        }
      }
    }
  }
}

/* LIST ------------------  */
.fa-check-circle {
  color:#c0001f
}
.list-partners {
  margin:30px 0px 0px 0px;
  padding:0px;
  list-style: none;
  width:100%;
}
.list-partners h2 {
  margin-top:0px;
  padding:0px;
  font-size:24px;
  color:#c0001f;
}
.list-partners li {
  width:100%;
  float:left;
  display:block;
  padding:30px 0px;
  border-top:1px solid #eee;
}
.list-partners li:nth-child(even) {
  background-color: #fcfcfc;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fcfcfc), to(#ffffff)); /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, #fcfcfc, #ffffff); /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image:    -moz-linear-gradient(top, #fcfcfc, #ffffff); /* FF3.6+ */
  background-image:     -ms-linear-gradient(top, #fcfcfc, #ffffff); /* IE10 */
  background-image:      -o-linear-gradient(top, #fcfcfc, #ffffff); /* Opera 11.10+ */
  background-image:         linear-gradient(to bottom, #fcfcfc, #ffffff);
}
.list-partners li:nth-child(odd) {
  background-color: #ffffff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#fcfcfc)); /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(top, #ffffff, #fcfcfc); /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image:    -moz-linear-gradient(top, #ffffff, #fcfcfc); /* FF3.6+ */
  background-image:     -ms-linear-gradient(top, #ffffff, #fcfcfc); /* IE10 */
  background-image:      -o-linear-gradient(top, #ffffff, #fcfcfc); /* Opera 11.10+ */
  background-image:         linear-gradient(to bottom, #ffffff, #fcfcfc);
}
.list-partners li:last-child {
  border-bottom:0px;
}
.list-partners li.activiteit h4 {
  color:#c0001f;
  font-size:16px;
  padding-top:15px;
  padding-left:10px;
}
.list-partners li.activiteit .fa-ul {
  color:#aaa;
}
.list-partners li.activiteit .fa-ul li {
  background-color: transparent;
  padding:0px;
}
.list-partners li.activiteit .info {
  padding-left:0px;
}
#maatschappelijkepartners .btn-primary i {
  padding-right:10px;
}
#maatschappelijkepartners .btn-primary {
  background:#ffffff;
  color:#c0001f;
  border:0px;
  width:100%;
  display:block;
  text-align: left;
  padding-left:0px;
  border-bottom:1px solid #eee
}
#maatschappelijkepartners .btn-primary.active,
#maatschappelijkepartners .btn-primary.focus,
#maatschappelijkepartners .btn-primary:active,
#maatschappelijkepartners .btn-primary:focus,
#maatschappelijkepartners .btn-primary:hover,
#maatschappelijkepartners .open>.dropdown-toggle.btn-primary {
  border-color:#eee;
  outline:0;
}
.maat-projecten {
  margin-top:30px;
}
#contactopnemen .fa-ul {
  margin-bottom:30px;
}
#contactopnemen .fa-li {
  top:.38285714em;
  color:#c0001f;
  text-align:left;
}
#contactopnemen h3 {
  padding:0 0 15px 0;
}
.fa-li {
  top: .38285714em;
}

#organisatie h2 {
  margin-top:42px;
  color:#c0001f
}
#organisatie h3 {
  margin-top:0;
}

footer {
  background:#000000;
  padding:15px 0px 5px 0px;
  font-size:12px;
}
footer p {
  margin:0 0 7px 0;
}

.fix_btn {
  display:block;
  position: fixed;
  right:0;
  top:45%;
  z-index:1199;
  padding:15px 15px 15px 20px;
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;

  background-color:#faf9f9;
  border-left:1px solid #dddddd;
  border-top:1px solid #dddddd;
  border-bottom:1px solid #dddddd;
}
.fix_btn {
  color:#bbb;
}
.fix_btn:hover {
  color:#ffffff;
  border-left:1px solid #5a1516;
  border-top:1px solid #5a1516;
  border-bottom:1px solid #5a1516;
}

/* MEDIA QUERIES */
@media(max-width:991px){
  .s-overlay {
    display:none;
  }
  header {
    position:relative;
  }
  header .logo-wrapper {
    position:relative;
    width:100%;
    height:auto;
    padding-top:30px;
    padding-bottom:15px;
  }
  header .logo-container {
    padding: 5px 30px 0;
    width:100%;
    position:relative;
    width:500px;
    height:95px;
  }
  header .logo-container .pull-right {
    float:left !important;
  }
  header .logo-container h2 {
    text-align:left !important;
    margin-top:5px;
  }
  header .bg-header {
    height:320px;
  }
  header .bg-header a {
    margin-top:250px;
  }
  h1 {
    margin-bottom:30px;
  }
  .navbar-default .navbar-nav > li > a {
    padding-right:21px;
  }
  #testimonials .image img {
    max-width:150px;
  }
  #testimonials .testimonial-text div {
    margin-left:50px;
  }
  #testimonials .testimonial-text h2 {
    font-size:20px;
    padding-left:0px;
  }
  #testimonials .testimonial-text h3 {
    font-size:16px;
    padding-left:0px;
  }
  .image-activiteit {
    padding-left:0px !important;
  }
  .image-activiteit img {
    margin-top:30px;
  }
  .list-partners li.activiteit h4 {
    padding-left:0px;
  }
  .list-partners li.activiteit .fa-ul {
    margin-left: 1.54285714em;
  }
  #watdoenwij .fa-2x {
    font-size:1.2em;
  }
}
@media(max-width:767px){
  body,
  html {
    font-size:17px;
  }
  h1 {
    font-size:24px;
  }
  h2.title {
    i {
      font-size:20px;
    }
  }
  #intro h1 {
    text-align:left;
  }
  section.space-lg {
    padding:25px 0px;
  }
  #partners {
    padding-bottom:0px;
  }
  #partners .image {
    margin-bottom:30px;
  }

  header .bg {
    background-size:100%;
    min-height:150px;
  }
  header,
  header .bg,
  header .bg .container{
    height:100%;
  }
  header .logo-wrapper {
    padding-top:15px;
    padding-bottom:0px;
  }
  header .logo-container {
    padding-left:15px;
    padding-right:15px;
    max-width:100%;
    height:80px;
  }
  header .bg-header {
    height:240px;
  }
  header .partner-worden {
    position: relative;
  }
  header .bg-header a {
    z-index:1001;
    margin-top:140px;
  }
  header h2 {
    font-size:16px;
  }
  .fix_btn {
    padding:5px 5px 5px 10px
  }

  .navbar-default {
    border-top:1px solid #ffffff;
    margin-bottom: 0px;
    background-color: #000000;
  }
  .navbar-default .navbar-toggle {
    border:1px solid #c0001f;
    background:#c0001f;
  }
  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover {
    background:#ffffff;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
  }
  .navbar-default .navbar-toggle:focus .icon-bar,
  .navbar-default .navbar-toggle:hover .icon-bar {
    background-color: #c0001f;
  }

  /* testimonials */
  #testimonials,
  .swiper-container,
  #testimonials .testimonial-logo,
  #testimonials .testimonial-text {
    height:auto;
  }
  #testimonials .image,
  #testimonials .testimonial-text{
    display: table;
    height:225px;
    width:100%;
  }
  #testimonials .testimonial-text h2,
  #testimonials .testimonial-text h3 {
    color:#000;
  }
  #testimonials .testimonial-text h2:before {
    content:"";
  }
  #testimonials .testimonial-text h2:after {
    content: "";
  }
  .bgcolor {
    display:none;
  }
  #testimonials .testimonial-text {
    padding:0px 0px 0px 30px;
  }
  #partners {
    margin-top:0;
  }
  .sponsor-content.three-star {
    min-height:auto;
  }
  #sponsoredby {
    p {
      font-size:18px;
    }
  }
  .swiper-slide {
    .image {
      .rates {
        top: -20px !important;
        i {
          font-size:12px;
        }
      }
    }
  }
}
@media(max-width:550px){
  header .bg {
    background-size:100%;
    min-height:120px;
  }
  header .bg-header {
    height:180px;
  }
  #testimonials .testimonial-text {
    padding-left:0px;
  }
  #testimonials .testimonial-text h2,
  #testimonials .testimonial-text h3 {
    text-align:center;
  }
  #testimonials .testimonial-logo {
    display:none
  }
  header .bg-header a {
    z-index:1001;
    margin-top:40px;
  }
}
@media(max-width:480px){
  header .bg-header {
    height:140px;
  }
}
@media(min-width:768px){
  .navbar-default {
    background: rgba(48,48,48,1);
    background: -moz-linear-gradient(top, rgba(48,48,48,1) 0%, rgba(48,48,48,1) 50%, rgba(0,0,0,1) 51%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(48,48,48,1)), color-stop(50%, rgba(48,48,48,1)), color-stop(51%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,0,1)));
    background: -webkit-linear-gradient(top, rgba(48,48,48,1) 0%, rgba(48,48,48,1) 50%, rgba(0,0,0,1) 51%, rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(top, rgba(48,48,48,1) 0%, rgba(48,48,48,1) 50%, rgba(0,0,0,1) 51%, rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(top, rgba(48,48,48,1) 0%, rgba(48,48,48,1) 50%, rgba(0,0,0,1) 51%, rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(48,48,48,1) 0%, rgba(48,48,48,1) 50%, rgba(0,0,0,1) 51%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#303030', endColorstr='#000000', GradientType=0 );
    position:absolute;
    left:0;
    right:0;
  }
  .navbar-default .navbar-nav > li > a {
    font-size:18px;
    padding-left:0px;
  }
  #testimonials .testimonial-text h2:before {
    left:-25px;
    font-size:26px
  }
  #testimonials .testimonial-text h2:after {
    bottom:0px;
    left:5px;
    font-size:26px
  }
}
@media(min-width:992px){
  .navbar-default .navbar-nav > li > a {
    font-size:16px;
    padding-left:35px;
  }
  #testimonials .testimonial-text h2:before {
    left:55px;
    top:0px;
    font-size:32px
  }
  #testimonials .testimonial-text h2:after {
    font-size:32px;
  }
  header .logo-container {
    display: block;
    position: relative;
    padding:177px 0px 0px 15px;
    width:385px;
    right:-30px;
  }
  header .container-fluid .col-sm-8 {
    z-index:10;
  }

  header .logo {
    float:left;
    width:400px;
    z-index:1000;

  }
  header .bg-header {
    background:url(/images/headers/header-01.jpg) no-repeat top right;
    background-size:cover;
    height:398px;
    overflow:hidden;
    position:relative;
    padding:0px !important;
  }
  header .bg .container {
    position: relative;
    height:398px;
  }
  header .bg .btn {
    position:absolute;
    right:0;
    bottom:40px;
    z-index:10;
  }
  header a.btn {
    margin-top:300px;
  }
  .swiper-container h2 {
    font-size:23px;
  }
  .swiper-container h3 {
    font-size:20px;
  }
}
@media(min-width:1200px){
  .navbar-default .navbar-nav > li > a {
    font-size:18px;
    padding-left:44px;
    padding-right:30px;
  }
  .navbar-default .navbar-nav > li:hover a:before,
  .navbar-default .navbar-nav > li.active a:before {
    color:#c0001f;
    content:"\f1e3";
    font-size:15px;
    font-family:FontAwesome;
    font-style:normal;
    font-weight:normal !important;
    line-height:1;
    left: 21px;
    top: 18px;
    position:absolute;
  }
}
.swiper-container.swiper2 {
  overflow:visible;
}
.text-container {
  ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
    li {
      position: relative;
      list-style:none;
      &:before {
        position: absolute;
        left: -2.14285714em;
        width: 2.14285714em;
        top: .38285714em;
        text-align: center;
        display: inline-block;
        content: "\f00c";
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);
      }
    }
  }
}
.btn-arrow-right {
  padding-right:40px;
  &:after {
    content: "\f054";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    position:absolute;
    right:15px;
    top:15px;
  }
}
.btn-arrow-left {
  padding-left:40px;
  &:after {
    content: "\f053";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    position:absolute;
    left:15px;
    top:15px;
  }
}
.swiper-slide {
  .image {
    .rates {
      position:absolute;
      right:5px;
      top:5px;
      z-index:101;
      .fa-star {
        position:relative;
        float:left;
      }
    }

  }
}
.image-left,
.image-right {
  float:left;
}
.image-left {
  margin-right:20px;
}

.fancybox-content {
  width  : 800px;
  height : 500px;
  max-width  : 80%;
  max-height : 80%;
  margin: 0;
}
.modal-fancybox h2 {
  margin:20px 0;
  padding:0 0 6px 0;
  color:#c0001f;
  display:block;
  width:100%;
  border-bottom:1px solid #ccc;
}